<template>
  <section>
    <div class="content-header">
      <h2>About community resilience</h2>
    </div>
    <div class="content-wrapper">
      <p>What does community resilience refer to, exactly? Well, we know that individual differences in learning and wellbeing emerge in <strong>contexts</strong>—within different communities, and over developmental time. Our classrooms are one community in which these individual differences emerge, and we have a particular opportunity to build classroom communities that are conducive to all students’ academic and personal resilience.</p>
      <p><strong>Together, we need to build classroom communities where the complexities of the humans in front of us have space to be seen, heard and valued. </strong></p>
      <p>This section on community resilience will help you learn about the complexity of the humans you teach, while offering tangible strategies to build classroom communities that mitigate barriers, promote equity and foster wellbeing for all learners, particularly those experiencing elevated risk for distress and barriers to learning.</p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
